import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Transition } from 'react-transition-group';
import { useSelector } from 'react-redux';
import classNames from 'classnames/bind';

// Icons
import DeliveryAlertIcon from '../../../icons/deliveryAlert.svg';
import CloseIcon from '../../../icons/close.svg';

// Components
import AppLink from '../../../components/AppLink';

// Router
import { paths, getRoute } from '../../../entry/routes';

// Utils
import isomorphicCookies from '../../../utils/cookie/isomorphicCookies';

// Styles
import styles from './DeliveryAlert.styl';

const cx = classNames.bind(styles);

export default function DeliveryAlert() {
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);

  const deliveryAreaAlert = useSelector(state => state.city.userCity.delivery_area_alert);

  const handleOnClose = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    const deliveryAlertIsShown = isomorphicCookies().get('delivery_alert_is_shown');

    if (deliveryAreaAlert && !deliveryAlertIsShown) {
      isomorphicCookies().set('delivery_alert_is_shown', 1, { expires: 7 });
      setIsOpen(true);
    }
  }, []);

  return (
    <Transition in={isOpen} timeout={{ enter: 0, exit: 175 }} mountOnEnter unmountOnExit>
      {state => (
        <div className={cx('DeliveryAlert', `DeliveryAlert_${state}`)}>
          <button className={cx('DeliveryAlert__close')} onClick={handleOnClose} type="button">
            <CloseIcon />
          </button>
          <div className={cx('DeliveryAlert__block')}>
            <DeliveryAlertIcon className={cx('DeliveryAlert__pic')} />
            <div className={cx('DeliveryAlert__container')}>
              <div className={cx('DeliveryAlert__title')}>
                {intl.formatMessage({ id: 'deliveryAlert.title' })}
              </div>
              <div className={cx('DeliveryAlert__text')}>
                {intl.formatMessage({ id: 'deliveryAlert.text' })}{' '}
                <AppLink to={getRoute(paths.contacts)}>
                  {intl.formatMessage({ id: 'deliveryAlert.link' })}
                </AppLink>
              </div>
            </div>
          </div>
        </div>
      )}
    </Transition>
  );
}
